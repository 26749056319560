import log from "loglevel";
import type { NextPage } from "next";
import { useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import StartUpError from "../components/errorpages/StartUpError";
import LandingPage from "../components/LandingPage";
import RedSiftLoadingIndicator from "../components/RedSiftLoadingIndicator";
import SiftSelector from "../components/SiftSelector";
import SiftSwitcherDrawer from "../components/SiftSwitcherDrawer";
import useAppStartup from "../hooks/useAppStartUp";
import {
  getSiftInfoFromPath,
  getUtmParams,
  modifySiftUrl,
} from "../libs/sift-utils";
import {
  getExcludedApps,
  hasAccessToHardenize,
  hasClosedBetaAccess,
  hasTools,
  isAdmin,
  isCustSupport,
  logout,
} from "../libs/utils";

declare global {
  var mixpanel: any;
}

const IdleTimer = ({ timeout }: { timeout: number | undefined }) => {
  const onIdle = () => {
    log.info("IdleTimer::onIdle::logging out");
    logout();
  };

  const idleTimer = useIdleTimer({
    timeout: timeout ? timeout * 60 * 1000 : 24 * 60 * 60 * 1000,
    onIdle,
  });

  return null;
};

const Home: NextPage = () => {
  console.log("validate this is updating ");
  const [selected, setSelected] = useState<any>({
    guid: undefined,
    instance: undefined,
  });
  const [path, setPath] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { isLoading, error, data } = useAppStartup();
  const setSelectedSift = useCallback(
    (state: any, preserveLocation: boolean = true) => {
      log.debug(
        "Home::setSelectedSift::",
        state,
        preserveLocation,
        window.location.pathname
      );
      const utmQueryParams = getUtmParams(window.location.search);
      const { guid, instance, ...loc } = getSiftInfoFromPath(data);
      modifySiftUrl(
        state,
        "push",
        preserveLocation ? loc : { search: utmQueryParams }
      );
      setSelected(state);
    },
    [data]
  );

  useEffect(() => {
    log.debug("Home::useEffect:", isLoading, error);
    // Infer the GUID of the selected sift from the location if possible and set selected
    if (!isLoading) {
      if (data && data.user) {
        setEmail(data.user.email);
        mixpanel.identify(data.user.email);
      }
      if (data && data.sifts) {
        const { guid, instance, ...rest } = getSiftInfoFromPath(data);
        let pathname,
          search = "";
        if ("pathname" in rest) {
          pathname = rest.pathname;
        }
        if ("search" in rest) {
          search = rest.search;
        }
        if (guid || instance) {
          log.debug(
            "Home::useEffect::setSelectedSift::guid,instance",
            guid,
            instance
          );
          setPath(`${pathname}${search}`);
          setSelectedSift({ guid, instance });
        }
      } else {
        const { guid } = getSiftInfoFromPath();
        setSelected({ guid, instance: undefined });
      }
    }
  }, [isLoading, error, data, setSelectedSift, path]);
  // TODO: dynamically set "hasTools" based on list of sifts and/or user attributes
  return (
    <div style={{ minHeight: "100vh", width: "100vw" }}>
      {isLoading && <RedSiftLoadingIndicator />}
      {!isLoading && <IdleTimer timeout={data?.config.idleTimer} />}
      {!isLoading && error && <StartUpError type={error} />}
      {!isLoading && !selected.guid && !error && (
        <LandingPage
          setSelected={setSelectedSift}
          excludeApps={getExcludedApps(data?.config, data?.sifts)}
        />
      )}
      <SiftSelector
        isLoading={isLoading}
        isError={!!error}
        selected={selected}
        setSelected={setSelectedSift}
        user={data?.user}
        // user={data?.user ? { attributes: { multiInstanceAdmin: true, tools: ["q7venKqTTl20ytgeT6XV2nZFbGiGSyHN2PpyIeiSm0GHngx8.1"] }, ...data.user } : undefined } // NOTE: stub to emulate MSP/multiInstance admin user
        jwt={data?.jwt}
        sifts={data?.sifts}
        siftsJwt={data?.siftsJwt}
        // isAdmin={true} // NOTE: Stub to show admin options for a user
        isAdmin={isAdmin(data?.user)}
        // isCustSupport={true} // NOTE: Stub to show admin options for a user
        isCustSupport={isCustSupport(data?.user)}
        pathname={path}
      />
      <SiftSwitcherDrawer
        excludeApps={getExcludedApps(data?.config, data?.sifts)}
        // hasTools={true} // NOTE: Stub to show tools for a user
        hasTools={hasTools(data?.user)}
        // isAdmin={true} // NOTE: Stub to show admin options for a user
        isAdmin={isAdmin(data?.user)}
        // isCustSupport={true} // NOTE: Stub to show admin options for a user
        isCustSupport={isCustSupport(data?.user)}
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelectedSift}
        email={email}
        hasBetaAccess={hasClosedBetaAccess(data?.user)}
        setPath={setPath}
        sifts={data?.user?.sifts}
        hasAccessToHardenize={hasAccessToHardenize(data?.user)}
      />
    </div>
  );
};

export default Home;
