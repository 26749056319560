///////////////////////////////////////////////////////////////////////////
// Connects and orchestrates: Sync worker, Sift controller and Sift view.
///////////////////////////////////////////////////////////////////////////
import RSUtils from "@redsift/rs-utils";
import log from "loglevel";
import {
  CLASS_SUMMARY,
  CLIENT_ID,
  OAUTH,
  PLUGIN_CONFIGS,
  REDSIFT_HEADER_PREFIX,
  RPC_URL,
  SIZE_FULL_SCREEN,
  SYNC_HOST,
} from "./constants";
import { getRouteLocationForSift, modifySiftUrl } from "./sift-utils";
import {
  logout,
  requestShortlivedCode,
  showOAuthPopup,
  showOAuthRemovePopup,
  showSlackAuth,
  showTill,
} from "./utils";

export default class SiftRoundabout {
  private ctx: any; // The application context
  private syncWorkerRef: any; // Web Worker ref
  private siftControllerRef: any; // Web Worker ref
  private siftViewRef: any; // Iframe ref
  private sift: any; // The sift instance object

  private windowClientMessageHandler: any;
  private windowClientPopstateHandler: any;

  private siftPermissionsConfig: any;

  constructor(
    ctx: any,
    sift: any,
    syncWorkerRef: any,
    siftControllerRef: any,
    siftViewRef: any,
    siftPermissionsConfig: any
  ) {
    this.ctx = ctx;
    this.sift = sift;
    this.syncWorkerRef = syncWorkerRef;
    this.siftControllerRef = siftControllerRef;
    this.siftViewRef = siftViewRef;

    this.siftPermissionsConfig = siftPermissionsConfig;
  }

  // Starts everything
  public letsGo() {
    this.initWindowClient();
    this.initSyncWorker();
    this.initSiftController();
    // Sift controller will init sift view when the time is right
  }

  public terminate() {
    this.syncWorkerRef.current?.terminate();
    this.siftControllerRef.current?.terminate();
    window.removeEventListener("message", this.windowClientMessageHandler);
    window.removeEventListener("popstate", this.windowClientPopstateHandler);
  }

  public postSiftViewMessage(method: string, params: any) {
    if (this.siftViewRef && this.siftViewRef.current) {
      this.siftViewRef.current.contentWindow.postMessage(
        {
          method,
          params,
        },
        "*"
      );
    } else {
      log.warn("sift-roundabout::postSiftViewMessage::iframe not ready yet");
    }
  }

  private initSyncWorker() {
    this.syncWorkerRef.current = new Worker(
      new URL("../workers/sync-worker.js", import.meta.url)
    );
    this.syncWorkerRef.current.onmessage = (evt: MessageEvent) => {
      const { method, params } = evt.data;
      switch (method) {
        case "storageUpdated":
          // Send to controller
          this.siftControllerRef.current?.postMessage({ method, params });
          break;
        case "sync/estimates":
          // NOTE: update loading indicator if one used
          break;
        case "started":
          // Don't care
          break;
        case "logout":
          logout();
          break;
        default:
          log.warn(
            "sift-roundabout::syncWorker::onmessage::unexpected event:",
            method,
            params
          );
          break;
      }
    };
    this.syncWorkerRef.current.onerror = (evt: MessageEvent) => {
      log.error("sift-roundabout::syncWorker::onerror", evt);
      // Present error and log the user out
      this.ctx.setAlert("Error synchronising data", "error");
      logout();
    };
    this.syncWorkerRef.current.postMessage({
      method: "start",
      params: {
        sift: {
          id: this.sift.id,
          guid: this.sift.guid,
          siftJSON: this.sift.siftJSON,
        },
        jwt: this.sift.syncJwt,
        syncHost: SYNC_HOST,
      },
    });
  }

  private async initSiftController() {
    // Fetch the controller script to create a blob
    const controllerScript = await fetch(
      this.sift.web_url + this.sift.siftJSON.interfaces.summary.controller
    ).then((resp) => resp.text());
    // Crate blob
    const controllerBlob = new Blob([controllerScript]);
    const URL = window.URL || window.webkitURL;
    // Create sift controller web worker
    this.siftControllerRef.current = new Worker(
      URL.createObjectURL(controllerBlob)
    );
    // Register for message events from the sift controller
    this.siftControllerRef.current.onmessage = (evt: MessageEvent) => {
      log.debug("sift-roundabout::siftControllerRef::onmessage:", evt);
      const { method, params } = evt.data;
      switch (method) {
        case "initCallback":
          log.debug(
            "sift-roundabout::siftControllerRef::onmessage:initCallback"
          );
          this.siftControllerRef.current.postMessage({
            method: "initPlugins",
            params: { pluginConfigs: PLUGIN_CONFIGS },
          });
          this.siftControllerRef.current.postMessage({
            method: "loadView",
            params: {
              client: CLIENT_ID,
              type: CLASS_SUMMARY,
              sizeClass: SIZE_FULL_SCREEN,
              data: {
                rpcApiConfig: {
                  apiToken: this.sift.siftJwt,
                  baseUrl: RPC_URL,
                  brandHeaderPrefix: REDSIFT_HEADER_PREFIX,
                  userAccountId: this.sift.id,
                },
                siftPermissionsConfig: this.siftPermissionsConfig,
                routeLocation: getRouteLocationForSift(),
                session: {
                  // NOTE: misnomer, old cloud used to have sessions and now kept as some of its non-session parameters are still used
                  guid: this.sift.guid,
                  // TODO: add support for public sift
                  isPublic: false,
                },
                oauth: OAUTH[this.sift.guid]
                  ? {
                      email:
                        this.siftPermissionsConfig.currentUser.profile.email,
                      // TODO: if we know the account that the user has oauth'ed with we should restrict to just that provider
                      ...OAUTH[this.sift.guid],
                    }
                  : {},
              },
            },
          });
          this.siftControllerRef.current.postMessage({
            method: "startPlugins",
            params: { pluginConfigs: PLUGIN_CONFIGS },
          });
          break;
        case "loadViewCallback":
          const { data, html } = params.result;
          log.debug(
            `sift-roundabout::siftControllerRef::onmessage::loadViewCallback: html: ${html} data:`,
            data
          );
          // Initialise sift view
          this.initSiftView(data, html);
          break;
        case "notifyView":
          this.postSiftViewMessage(method, params);
          break;
        default:
          log.warn(
            "sift-roundabout::siftControllerRef::onmessage:unhandled",
            method,
            params
          );
          break;
      }
    };
    this.siftControllerRef.current.onerror = (evt: MessageEvent) => {
      log.debug("sift-roundabout::siftControllerRef::onerror:", evt);
    };
    this.siftControllerRef.current.postMessage({
      method: "init",
      params: {
        accountGuid: this.sift.id,
        siftGuid: this.sift.guid,
        dbSchema: new RSUtils().getDbSchema(this.sift.siftJSON),
      },
    });
  }

  private initSiftView(data: any, html: string) {
    if (!this.siftViewRef.current) {
      // This seems to be the case when the browser goes offline
      log.warn("sift-roundabout::initSiftView::no available sift view");
      return;
    }
    // When the iframe loads, initiate plugins and post presentView message
    this.siftViewRef.current.onload = (evt: MessageEvent) => {
      log.debug("sift-roundabout::siftViewRef::onload", evt);
      this.siftViewRef.current.contentWindow.postMessage(
        {
          method: "_initPlugins",
          params: { pluginConfigs: PLUGIN_CONFIGS },
        },
        "*"
      );
      this.siftViewRef.current.contentWindow.postMessage(
        {
          method: "presentView",
          params: {
            client: CLIENT_ID,
            type: CLASS_SUMMARY,
            sizeClass: SIZE_FULL_SCREEN,
            data,
          },
        },
        "*"
      );
      this.siftViewRef.current.contentWindow.postMessage(
        {
          method: "_startPlugins",
          params: { pluginConfigs: PLUGIN_CONFIGS },
        },
        "*"
      );
    };
    // Load the sift view page
    this.siftViewRef.current.src = this.sift.web_url + html;
  }

  private initWindowClient() {
    this.initWindowClientMessageHandler();
    this.initWindowClientPopStateHandler();
  }

  // NOTE: window message handler assumes only one active sift
  // if we ever need to do tiling/split screen this will have to change
  private initWindowClientMessageHandler() {
    this.windowClientMessageHandler = (event: any) => {
      log.debug("sift-roundabout::windowClientMessageHandler:", event.data);
      const { method, params } = event.data;
      switch (method) {
        case "notifyClient":
          const { topic, value } = params;
          switch (topic) {
            case "sync-history":
              modifySiftUrl(
                { guid: this.sift.guid, instance: this.sift.id },
                value.action || "push",
                value
              );
              break;
            case "showOAuthPopup":
              log.debug(
                "sift-roundabout::windowClientMessageHandler::showOAuthPopup"
              );
              showOAuthPopup(
                this.ctx,
                this.siftPermissionsConfig.currentUser.id,
                value.provider,
                this.sift.id,
                this.sift.guid,
                value.options
              );
              break;
            case "showSlackAuth":
              log.debug(
                "sift-roundabout::windowClientMessageHandler::showSlackAuth"
              );
              showSlackAuth(this.ctx, this.sift.id, this.sift.guid);
              break;
            case "showOAuthRemovePopup":
              log.debug(
                "sift-roundabout::windowClientMessageHandler::showOAuthRemovePopup"
              );
              showOAuthRemovePopup(
                this.ctx,
                this.sift.id,
                this.sift.guid,
                value.provider
              );
              break;
            case "showTill":
              log.debug(
                "sift-roundabout::windowClientMessageHandler::showTill"
              );
              showTill(this.sift.id, this.sift.guid);
              break;
            case "requestShortlivedCode":
              log.debug(
                "sift-roundabout::windowClientMessageHandler::requestShortlivedCode"
              );
              requestShortlivedCode(value).then(({ code, userId, error }) => {
                log.debug(
                  "sift-roundabout::windowClientMessageHandler::requestShortlivedCode::code",
                  code,
                  userId
                );
                if (!error && code && userId) {
                  const { path } = value;
                  this.postSiftViewMessage("sendShortlivedCode", {
                    code,
                    userId,
                    path,
                  });
                } else {
                  log.error(
                    "sift-roundabout::windowClientMessageHandler::requestShortlivedCode::error",
                    error
                  );
                }
              });
              break;
            case "toggleDrawer":
              this.ctx.setRightDrawerOpen((value: Boolean) => !value);
              break;
            default:
              log.error(
                "sift-roundabout::windowClientMessageHandler::notifyClient:unexpected topic:",
                topic,
                value
              );
          }
          break;
        case "notifyController":
          log.debug(
            "sift-roundabout::windowClientMessageHandler::notifyController::sending to controller"
          );
          // Send to controller
          this.siftControllerRef.current?.postMessage({ method, params });
          break;
        case "willPresentView":
          log.debug(
            "sift-roundabout::windowClientMessageHandler::willPresentView::sending to view"
          );
          this.postSiftViewMessage(method, {
            client: CLIENT_ID,
            type: CLASS_SUMMARY,
            sizeClass: SIZE_FULL_SCREEN,
          });
          break;
        default:
          log.warn(
            "sift-roundabout::windowClientMessageHandler::unsupported method:",
            method
          );
          break;
      }
    };
    window.addEventListener("message", this.windowClientMessageHandler);
  }

  // Synchronises the sift with any use of the back/forward browser buttons
  private initWindowClientPopStateHandler() {
    this.windowClientPopstateHandler = (event: any) => {
      log.debug(
        "sift-roundabout::initWindowClientPopStateHandler::windowClientPopstateHandler:",
        event,
        getRouteLocationForSift(document.location)
      );
      const routeLocation = getRouteLocationForSift(document.location);
      const messages = [
        {
          id: "sync-history",
          data: {
            action: "push",
            location: routeLocation,
          },
        },
      ];
      this.postSiftViewMessage("_receivePluginMessages", { messages });
    };
    window.addEventListener("popstate", this.windowClientPopstateHandler);
  }
}
