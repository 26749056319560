import { useContext, useMemo, useState } from "react";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import {
  Button,
  ButtonLink,
  Flexbox,
  IconButton,
  Item,
  Link,
  TextField,
} from "@redsift/design-system";
import log from "loglevel";
import { Combobox } from "@redsift/pickers";
import { Dialog } from "@redsift/popovers";

import { ALL_SIFTS } from "../libs/constants";
import { getIam, postIam, putIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import { mdiMagnify } from "@redsift/icons";

const TYPE_USER = "USER";

const getOrganizations = async (ctx: any, setOrganizations: Function) => {
  const resp = await getIam(`/api/admin/organizations?type=${TYPE_USER}`);
  if (resp.error) {
    log.error("getOrganizations::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setOrganizations(resp);
  }
};

const createNewOrganization = async (
  ctx: any,
  organizationEmail: string,
  organizationName?: string
) => {
  const resp: any = await postIam("/api/admin/organizations", {
    email: organizationEmail,
    type: TYPE_USER,
    ...(organizationName && {
      metadata: {
        name: organizationName,
      },
    }),
  });
  if (resp.error) {
    log.error("createNewOrganization::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert(
      `Successfully created organization for ${organizationEmail}`,
      "success"
    );
  }
};

const provisionPulse = async (organizationId: string, ctx: any) => {
  const guid = "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1";
  const sift = ALL_SIFTS[guid];
  if (!sift) {
    ctx.setAlert("Sift not available", "error");
    return;
  }
  const resp = await postIam(
    `/api/admin/provision?organizationId=${organizationId}`,
    {
      sift: {
        guid,
        collection: "catalogue",
        owner: "redsift",
        ...ALL_SIFTS[guid].sift,
      },
    }
  );
  if (resp.error) {
    log.error("provisionPulse::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert(`Successfully provisioned Pulse`, "success");
  }
};

const provisionHardenize = async (organizationId: string, ctx: any) => {
  const resp = await postIam(
    `/api/admin/organizations/${organizationId}/hardenize`,
    {}
  );
  if (resp.error) {
    log.error("provisionHardenize::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert(`Successfully provisioned Hardenize`, "success");
  }
};

const associateInstances = async (
  organizationId: string,
  ctx: any,
  ondmarcSift?: string,
  brandTrustSift?: string,
  vendorSecureSift?: string,
  certificatesSift?: string
) => {
  const sifts = [
    ...(ondmarcSift
      ? [
          {
            id: ondmarcSift,
            guid: "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1",
          },
        ]
      : []),
    ...(brandTrustSift
      ? [
          {
            id: brandTrustSift,
            guid: "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1",
          },
        ]
      : []),
    ...(vendorSecureSift
      ? [
          {
            id: vendorSecureSift,
            guid: "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1",
          },
        ]
      : []),
    ...(certificatesSift
      ? [
          {
            id: certificatesSift,
            guid: "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1",
          },
        ]
      : []),
  ];

  const resp = await postIam(
    `/api/admin/organizations/${organizationId}/sifts`,
    {
      sifts,
    }
  );
  if (resp.error) {
    log.error("associateInstances::", resp.error);
    ctx.setAlert(`Error: ${resp?.error}`, "error");
  } else {
    ctx.setAlert(`Successfully associated instances`, "success");
  }
};

const associatePulse = async (organizationId: string, id: string, ctx: any) => {
  const resp = await putIam(
    `/api/admin/provision?organizationId=${organizationId}`,
    {
      id,
      guid: "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1",
    }
  );
  if (resp.error) {
    log.error("associateInstances::", resp.error);
    ctx.setAlert(`Error: ${resp?.error}`, "error");
  } else {
    ctx.setAlert(`Successfully associated Pulse`, "success");
  }
};

const getSifts = async (product: string, ctx: any, setSifts: Function) => {
  const resp = await getIam("/api/admin/sifts", {
    guid: product,
  });
  if (resp.error) {
    log.error("getSifts::", resp);
    ctx.setAlert(resp.error, "error");
    return [];
  } else {
    setSifts(resp);
  }
};

const doesOrganizationHavePulse = (row: any) => {
  const sifts = row?.sifts || [];
  return sifts.some(
    (s: any) => s.guid === "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1"
  );
};

const doesOrganizationHavePulseAndHardenize = (row: any) => {
  const doesOrgHavePulse = doesOrganizationHavePulse(row);
  const isHardenizeProvisioned = row?.hardenizeOrganizationId;
  return doesOrgHavePulse && isHardenizeProvisioned;
};

const getOrgSift = (row: any, guid: string) => {
  const sifts = row?.sifts || [];
  const sift = sifts.find((s: any) => s.guid === guid);
  if (sift) {
    return sift.id;
  }
  return "None";
};

const AdminPulseProvisioner = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [openCreateOrganziationDialog, setOpenCreateOrganizationDialog] =
    useState(false);
  const [openAssociateInstanceDialog, setOpenAssociateInstanceDialog] =
    useState(false);
  const [organizationRow, setOrganizationRow] = useState<any>();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isProvisionPulseLoading, setIsProvisionPulseLoading] = useState(false);
  const [isProvisionHardenizeLoading, setIsProvisionHardenizeLoading] =
    useState(false);
  const [isAssociatingInstances, setIsAssociatingInstances] = useState(false);
  const [openAssociatePulseDialog, setOpenAssociatePulseDialog] =
    useState(false);
  const [sifts, setSifts] = useState<[]>();
  const [ondmarcSift, setOndmarcSift] = useState<any>();
  const [vendorSecureSift, setVendorSecureSift] = useState<any>();
  const [brandTrustSift, setBrandTrustSift] = useState<any>();
  const [certificatesSift, setCertificatesSift] = useState<any>();
  const [pulseSift, setPulseSift] = useState<any>();

  const hardenizeBaseUrl =
    process.env.NEXT_PUBLIC_PULSE_PROVISIONER_HARDENIZE_BASE_URL ||
    "https://hardenize.com/";

  const SUPPORTED_PRODUCTS = [
    {
      guid: "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1",
      name: "OnDMARC",
      sift: ondmarcSift,
      setSift: setOndmarcSift,
    },
    {
      guid: "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1",
      name: "Brand Trust",
      sift: brandTrustSift,
      setSift: setBrandTrustSift,
    },
    {
      guid: "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1",
      name: "Vendor Secure",
      sift: vendorSecureSift,
      setSift: setVendorSecureSift,
    },
    {
      guid: "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1",
      name: "Certificates",
      sift: certificatesSift,
      setSift: setCertificatesSift,
    },
  ];

  const PULSE = {
    guid: "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1",
    name: "Pulse",
    sift: pulseSift,
    setSift: setPulseSift,
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "Organization Id",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "metadata.name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.name,
        renderCell: ({ value }: any) => {
          return <TooltipCell value={value} />;
        },
      },
      {
        field: "metadata.description",
        headerName: "Description",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.description,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        flex: 1,
        valueGetter: ({ row }: any) => {
          if (!row?.createdAt) {
            return "No Date available";
          } else {
            return new Date(row?.createdAt).toString();
          }
        },
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "users",
        headerName: "Users",
        flex: 1,
        valueParser: ({ value }: any) => value,
        valueFormatter: ({ value }: GridValueFormatterParams) =>
          value.map((v: any) => `${v.email}`).join(" | "),
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={value.map((v: any) => `${v.email}`).join(" | ")}
          />
        ),
      },
      {
        field: "sifts",
        headerName: "Sifts",
        flex: 1,
        valueParser: ({ value }: any) => value,
        valueFormatter: ({ value }: GridValueFormatterParams) =>
          value
            .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
            .join(" | "),
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={value
              .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
              .join(" | ")}
          />
        ),
      },
      {
        field: "invitations",
        headerName: "Invitations",
        flex: 1,
        valueParser: ({ value }: any) => value,
        valueFormatter: ({ value }: GridValueFormatterParams) =>
          value.map((v: any) => `${v.email}`).join(" | "),
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={value.map((v: any) => `${v.email}`).join(" | ")}
          />
        ),
      },
      {
        field: "parent",
        headerName: "Parent Id",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "Associate Instances",
        headerName: "Associate Instances",
        flex: 1,
        renderCell: ({ row }: any) => (
          <Button
            isLoading={isProvisionPulseLoading}
            onClick={() => {
              setOrganizationRow(row);
              setOndmarcSift(undefined);
              setBrandTrustSift(undefined);
              setVendorSecureSift(undefined);
              setCertificatesSift(undefined);
              setOpenAssociateInstanceDialog(true);
              setIsAssociatingInstances(false);
            }}
          >
            Associate
          </Button>
        ),
      },
      {
        field: "Associate Pulse",
        headerName: "Associate Pulse",
        flex: 1,
        renderCell: ({ row }: any) => (
          <Button
            isLoading={isProvisionPulseLoading}
            disabled={doesOrganizationHavePulse(row)}
            onClick={() => {
              setOrganizationRow(row);
              setPulseSift(undefined);
              setOpenAssociatePulseDialog(true);
            }}
          >
            Pulse
          </Button>
        ),
      },
      {
        field: "ProvisionHardenize",
        headerName: "Provision Hardenize Organization",
        flex: 1,
        renderCell: ({ row }: any) => (
          <Button
            isLoading={isProvisionPulseLoading}
            disabled={doesOrganizationHavePulseAndHardenize(row)}
            onClick={async () => {
              setIsProvisionHardenizeLoading(true);
              await provisionHardenize(row.id, ctx);
              await getOrganizations(ctx, setOrganizations);
              setIsProvisionHardenizeLoading(false);
            }}
            color="error"
          >
            Hardenize
          </Button>
        ),
      },
      {
        field: "hardenizeOrganizationId",
        headerName: "Hardenize Organization Id",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
        hide: true,
      },
      {
        field: "hardenizeOrganizationUrl",
        headerName: "Hardenize Org URL",
        flex: 1,
        renderCell: ({ row }: any) => (
          <ButtonLink
            href={`${hardenizeBaseUrl}org/${row.hardenizeOrganizationId}`}
            isDisabled={!Boolean(row?.hardenizeOrganizationId)}
            target="_blank"
          >
            Open in Hardenize
          </ButtonLink>
        ),
        hide: true,
      },
    ],
    [ctx, hardenizeBaseUrl, isProvisionPulseLoading]
  );
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "wrap",
        justifyContent: "space-between",
      }}
    >
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          setLoading(true);
          ev.preventDefault();
          await getOrganizations(ctx, setOrganizations);
          setLoading(false);
        }}
      >
        <Button
          isLoading={loading}
          color="primary"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Load Organizations
        </Button>
      </form>
      <Button
        isLoading={loading}
        color="primary"
        type="submit"
        onClick={async () => {
          setOpenCreateOrganizationDialog(true);
        }}
        style={{ margin: "5px 0px 0px 5px" }}
      >
        Add new Organization
      </Button>

      <SearchableTable
        tableId="provisioner-explorer"
        columns={columns}
        rows={organizations}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              invitations: false,
              parent: false,
              ProvisionPulse: false,
              ProvisionHardenize: false,
              hardenizeOrganizationId: false,
              hardenizeOrganizationUrl: false,
            },
          },
        }}
      />
      <Dialog
        isOpen={openCreateOrganziationDialog}
        onOpen={setOpenCreateOrganizationDialog}
        size={"large"}
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            <form
              onSubmit={async (ev) => {
                setLoading(true);
                ev.preventDefault();
                await createNewOrganization(
                  ctx,
                  organizationEmail,
                  organizationName
                );
                await getOrganizations(ctx, setOrganizations);
                setLoading(false);
                setOrganizationEmail("");
                setOrganizationName("");
                setOpenCreateOrganizationDialog(false);
              }}
            >
              <Flexbox flexDirection="column" gap="6px">
                <TextField
                  id="email"
                  label="Email"
                  style={{ minWidth: 320, margin: "5px 0px 0px 5px" }}
                  value={organizationEmail}
                  isRequired
                  type="email"
                  onChange={(value) => {
                    setOrganizationEmail(value || "");
                  }}
                />
                <TextField
                  id="Name"
                  label="Name (optional)"
                  style={{ minWidth: 320, margin: "5px 0px 0px 5px" }}
                  value={organizationName}
                  onChange={(value) => {
                    setOrganizationName(value || "");
                  }}
                />
                <Button
                  isLoading={loading}
                  color="primary"
                  type="submit"
                  disabled={!organizationEmail}
                  style={{ margin: "5px 0px 0px 5px" }}
                >
                  Create Organization
                </Button>
              </Flexbox>
            </form>
          </Dialog.Content.Body>
        </Dialog.Content>
      </Dialog>
      <Dialog
        isOpen={openAssociateInstanceDialog}
        onOpen={setOpenAssociateInstanceDialog}
        size={"large"}
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            {SUPPORTED_PRODUCTS.map(
              (item: {
                guid: string;
                name: string;
                sift: any;
                setSift: Function;
              }) => (
                <div key={item.guid}>
                  <div>
                    {item.name}: {getOrgSift(organizationRow, item.guid)}
                  </div>
                  {getOrgSift(organizationRow, item.guid) === "None" && (
                    <Combobox
                      onChange={(value) => {
                        item.setSift(value);
                      }}
                      maxOptionsLength={15}
                    >
                      <Combobox.Trigger>
                        <TextField
                          label="Choose instance"
                          after={(value?: string, isDisabled?: boolean) => (
                            <IconButton
                              aria-label="Expand"
                              color="question"
                              icon={mdiMagnify}
                              isDisabled={isDisabled}
                              onClick={() => {
                                getSifts(item.guid, ctx, setSifts);
                              }}
                            />
                          )}
                        />
                      </Combobox.Trigger>
                      <Combobox.Content>
                        <Combobox.Content.Listbox>
                          {sifts?.map((item: { id: string; guid: string }) => (
                            <Item key={item.id} value={`${item.id}`}>
                              {item.id}
                            </Item>
                          ))}
                        </Combobox.Content.Listbox>
                      </Combobox.Content>
                    </Combobox>
                  )}
                </div>
              )
            )}
          </Dialog.Content.Body>
          <Dialog.Content.Actions justifyContent="space-between">
            <Flexbox>
              <Button onClick={() => setIsConfirmationDialogOpen(true)}>
                Save
              </Button>
            </Flexbox>
          </Dialog.Content.Actions>
        </Dialog.Content>
      </Dialog>
      <Dialog
        isOpen={openAssociatePulseDialog}
        onOpen={setOpenAssociatePulseDialog}
        size={"large"}
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            <div key={PULSE.guid}>
              <div>
                {PULSE.name}: {getOrgSift(organizationRow, PULSE.guid)}
              </div>
              <Combobox
                onChange={(value) => {
                  PULSE.setSift(value);
                }}
                maxOptionsLength={15}
              >
                <Combobox.Trigger>
                  <TextField
                    label="Choose instance"
                    after={(value?: string, isDisabled?: boolean) => (
                      <IconButton
                        aria-label="Expand"
                        color="question"
                        icon={mdiMagnify}
                        isDisabled={isDisabled}
                        onClick={async () => {
                          await getSifts(PULSE.guid, ctx, setSifts);
                        }}
                      />
                    )}
                  />
                </Combobox.Trigger>
                <Combobox.Content>
                  <Combobox.Content.Listbox>
                    {sifts?.map((item: { id: string; guid: string }) => (
                      <Item key={item.id} value={`${item.id}`}>
                        {item.id}
                      </Item>
                    ))}
                  </Combobox.Content.Listbox>
                </Combobox.Content>
              </Combobox>
            </div>
          </Dialog.Content.Body>
          <Dialog.Content.Actions justifyContent="space-between">
            <Flexbox>
              <Button
                isLoading={isAssociatingInstances}
                onClick={async () => {
                  setLoading(true);
                  await associatePulse(organizationRow.id, pulseSift, ctx);
                  await getOrganizations(ctx, setOrganizations);
                  setOpenAssociatePulseDialog(false);
                  setLoading(false);
                }}
              >
                Confirm
              </Button>
            </Flexbox>
          </Dialog.Content.Actions>
        </Dialog.Content>
      </Dialog>
      <Dialog
        isOpen={isConfirmationDialogOpen}
        onOpen={setIsConfirmationDialogOpen}
        size="medium"
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            Current Organization sifts:
            {SUPPORTED_PRODUCTS.map(
              (item: { guid: string; name: string }, index: number) => (
                <div key={item.guid}>
                  {item.name}: {getOrgSift(organizationRow, item.guid)}
                </div>
              )
            )}
            <strong>New state:</strong>
            {SUPPORTED_PRODUCTS.map(
              (item: { guid: string; name: string; sift: any }) => (
                <div key={item.guid}>
                  {item.name}:{" "}
                  {Boolean(item.sift)
                    ? item.sift
                    : getOrgSift(organizationRow, item.guid)}
                </div>
              )
            )}
            <Button
              isLoading={isAssociatingInstances}
              onClick={() => {
                setIsAssociatingInstances(true);
                associateInstances(
                  organizationRow.id,
                  ctx,
                  ondmarcSift,
                  brandTrustSift,
                  vendorSecureSift,
                  certificatesSift
                );
                setIsAssociatingInstances(false);
                setOpenAssociateInstanceDialog(false);
                setIsConfirmationDialogOpen(false);
                getOrganizations(ctx, setOrganizations);
              }}
            >
              Confirm
            </Button>
          </Dialog.Content.Body>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};

export default AdminPulseProvisioner;
